:root {
  --max-width: 1100px;
  --border-radius: 12px;

  --foreground-rgb: 35, 35, 35;
  --background-rgb: 238, 237, 237;

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  --white: #eeeded;
  --bright: #bdbdbd;
  --dark: #6b6b6b;
  --black: #232323;

  --green: #54b9a1;
  --orange: #f0b065;
  --red: #df5c64;

  --border-color: rgb(229, 231, 235);
}

.nx-bg-gray-100 {
  background-color: rgb(var(--background-rgb)) !important;
}

.swagger-ui {
  font-family: var(--font-sans) !important;
}
.swagger-ui h2 {
  font-family: var(--font-mono) !important;
  font-weight: 600 !important;
}

.swagger-ui pre code {
  display: inline;
}
